import React, { useEffect, useContext } from "react"
import cx from "classnames"
import Img from "gatsby-image"
import styled from "styled-components"
import ReactMarkdown from "react-markdown"
import { Heading, HeadingTitle, HeadingSubtitle } from "../components/Heading"
import { Column, Row } from "../components"
import { navigate, graphql } from "gatsby"
import { usePreferredLanguage } from "../util/usePreferredLanguage"
import { PageContext } from "../contexts/PageContext"
import { Container } from "../components/Container"
import { StrapiMobileTopAction } from "../strapi/StrapiMobileTopAction"
import { StrapiFooter } from "../strapi/StrapiFooter"
import { StrapiTopBar } from "../strapi/StrapiTopBar"
import { StrapiNavigation } from "../strapi/StrapiNavigation"
import * as constants from "../strapi/strapiComponentConstants";

import "../styles.css"

const selfAlignClasses = ({ align }) => {
  switch (align) {
    case "left":
      return "self-start"
    case "center":
      return "self-center"
    case "right":
      return "self-end"
    default:
      return null
  }
}

const alignClasses = ({ align }) => {
  switch (align) {
    case "left":
      return "justify-start"
    case "center":
      return "justify-center"
    case "right":
      return "justify-end"
    default:
      return null
  }
}

const textAlignClasses = ({ alignText }) => {
  switch (alignText) {
    case "left":
      return "text-left"
    case "center":
      return "text-center"
    case "right":
      return "text-right"
    default:
      return null
  }
}

const StrapiImageRenderer = ({ data }) => {
  return (
    <Img
      className={cx(selfAlignClasses(data), "my-m", "w-full")}
      fluid={data.image.localFile.childImageSharp.fluid}
      style={{ zIndex: "-1" }}
    />
  )
}

const renderers = {
  heading: ({ level, children }) => {
    return (
      <Heading level={`h${level}`}>
        <HeadingTitle children={children} />
      </Heading>
    )
  },
  paragraph: ({ node, children }) => {
    return <p className={"py-s"} children={children} />
  },
  blockquote: ({ children }) => {
    return (
      <blockquote
        className="my-s px-l py-s italic border-l-4"
        children={children}
      ></blockquote>
    )
  },
}

const TextBlock = styled(ReactMarkdown).attrs({
  renderers,
})``

const StrapiRichTextRenderer = ({ data }) => {
  const text = data.text

  return (
    <Row className={"justify-center"}>
      <TextBlock
        className={cx(
          "w-full",
          "max-w-prose",
          "text-body",
          textAlignClasses(data)
        )}
      >
        {text}
      </TextBlock>
    </Row>
  )
}

const StrapiTextRenderer = ({ data }) => {
  const text = data.text

  return (
    <Row className={"justify-center"}>
      <p
        className={cx(
          "w-full",
          "max-w-prose",
          "text-body",
          textAlignClasses(data)
        )}
      >
        {text}
      </p>
    </Row>
  )
}

const HeadlineRenderer = ({ data }) => {
  const title = data.strapi.location.portfolio_item.headline.title.text;
  const subtitle = data.strapi.location.portfolio_item.headline.subtitle.text;

  return (
    <Row className="justify-center">
      <Heading
        level={"display-2"}
        className={cx("w-full", "max-w-prose", "self-center")}
      >
        <HeadingSubtitle>{subtitle}</HeadingSubtitle>
        <HeadingTitle>{title}</HeadingTitle>
      </Heading>
    </Row>
  )
}

const YouTubeVideoRenderer = ({ data }) => {
  return (
    <Row className="justify-center my-m">
      <div className="max-w-2xl w-full">
        <div className="aspect-w-16 aspect-h-9 w-full">
          <iframe
            title={data.videoId}
            src={`https://www.youtube.com/embed/${data.videoId}`}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
      </div>
    </Row>
  )
}

export const PortfolioItemContent = () => {
  const { data } = useContext(PageContext)

  const content = data?.strapi.location.portfolio_item?.content || []

  return (
    <>
      {content.map(c => {
        const key = `${c.__typename}--${c.id}`;
        switch (c.__typename) {
          case constants.PAGE_CONTENT_RICH_TEXT:
            return <StrapiRichTextRenderer data={c} key={key} />
          case constants.PAGE_CONTENT_TEXT:
            return <StrapiTextRenderer data={c} key={key} />
          case constants.PAGE_CONTENT_IMAGE:
            return <StrapiImageRenderer data={c} key={key} />
          case constants.PAGE_CONTENT_YOUTUBE_VIDEO:
            return <YouTubeVideoRenderer data={c} key={key} />
          default:
            return null
        }
      })}
    </>
  )
}

export default function PortfolioItem({ pageContext, data }) {
  const {
    navigateToPreferredLanguage,
    language,
    defaultLanguage,
    languageLinks,
  } = pageContext

  const [preferredLang] = usePreferredLanguage({
    defaultLanguage,
    languages: Object.keys(languageLinks),
  })

  useEffect(() => {
    if (preferredLang !== language && navigateToPreferredLanguage) {
      navigate(languageLinks[preferredLang])
    }
  }, [language, languageLinks, navigateToPreferredLanguage, preferredLang])

  return (
    <PageContext.Provider value={{ ...pageContext, data }}>
      <StrapiMobileTopAction />
      <StrapiTopBar>
        <StrapiNavigation />
      </StrapiTopBar>
      <Container>
        <Column as="article">
          <HeadlineRenderer data={data} />
          <PortfolioItemContent />
        </Column>
      </Container>
      <StrapiFooter />
    </PageContext.Provider>
  )
}

export const query = graphql`
  query PortfolioItemData($id: ID!) {
    strapi {
      ...Footer
      ...NavigationActions
      ...NavigationMenus
      location(id: $id) {
        portfolio_item {
          ...PortfolioItem
        }
      }
    }
  }
`
